/* eslint-disable react/no-array-index-key */
import React from 'react';
import { string, arrayOf, shape } from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const propTypes = {
  lang: string,
  meta: arrayOf(shape({})),
  title: string.isRequired,
  path: string.isRequired,
  schemas: arrayOf(shape({})),
};

const defaultProps = {
  lang: `en`,
  meta: [],
  schemas: null,
};

const SEO = ({ lang, meta, title: rawTitle, path, schemas }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            author
            siteURL
          }
        }
      }
    `,
  );

  const { title: titleTemplate, author, siteURL } = site.siteMetadata;
  const title = `${rawTitle} | ${titleTemplate}`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          property: 'og:site_name',
          content: author,
        },
        {
          property: 'og:locale',
          content: lang,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:url',
          content: `${siteURL}${path}`,
        },
        {
          name: 'twitter:site',
          content: author,
        },
        {
          name: 'twitter:title',
          content: title,
        },
      ].concat(meta)}
    >
      <script type="text/javascript" defer>
        {`var _mfq = _mfq || [];
        (function() {
          var mf = document.createElement("script");
          mf.type = "text/javascript"; mf.async = true;
          mf.src = "//cdn.mouseflow.com/projects/09ab017a-ccf4-4c26-b91f-f6e8cd7c66f6.js";
          document.getElementsByTagName("head")[0].appendChild(mf);
        })();`}
      </script>

      {schemas &&
        schemas.map(({ schema }, idx) => (
          <script type="application/ld+json" key={`schema-${idx}`}>
            {schema}
          </script>
        ))}

      <link rel="canonical" href={`${siteURL}${path}`} />
    </Helmet>
  );
};

SEO.propTypes = propTypes;
SEO.defaultProps = defaultProps;

export default SEO;
